<template>
  <DialogFormWrapper hide-actions>
    <template #form>
      <div class="px-1">
        <Tabs
          v-if="course.swapCourse"
          :tabs="tabs"
          @goTo="goTo"
        />
      </div>
      <v-data-table
        class="history-table"
        hide-default-footer
        fixed-header
        :item-class="(item)=> item.critical && 'error--text'"
        :headers="headers"
        :items="currentTabHistory"
        :items-per-page="-1"
      />
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { dateStringFormat } from '../../utils'
import Tabs from '../Layout/Tabs.vue'
import { mapState, mapActions } from 'vuex'
import { Course } from '../../models'

export default {
  components: {
    DialogFormWrapper,
    Tabs
  },
  data: () => ({
    currentTab: 'order',
    tabs: [
      { value: 'order', text: 'Podstawienie' },
      { value: 'swapOrder', text: 'Zabranie' }
    ],
    headers: [
      { text: 'Czas zmiany', value: 'createdAt' },
      { text: 'Typ', value: 'changeType' },
      { text: 'Zmiana', value: 'text' },
      { text: 'Zmienione przez', value: 'changedBy' },
      { text: 'Rola', value: 'changedByType' }
    ]
  }),
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item,
      order: state => state.order.entity,
      swapOrder: state => state.order.swapOrder
    }),
    course () {
      return new Course(this.dialogItem)
    },
    currentTabHistory () {
      return this[this.currentTab]?.history?.map((item) => ({ ...item, createdAt: dateStringFormat(item.createdAt, true) }))
    },
  },
  created () {
    const { order, swapCourse } = this.course
    if (this.order.id !== order.id) {
      this.getSingleOrder({ id: order.id })
    }
    if (swapCourse && this.swapOrder.id !== swapCourse.order.id) {
      this.getSwapOrder(swapCourse.order.id)
    }
  },
  methods: {
    ...mapActions({
      getSingleOrder: 'order/getSingleOrder',
      getSwapOrder: 'order/getSwapOrder',
      closeDialog: 'layout/closeDialog'
    }),
    goTo (tab) {
      this.currentTab = tab.value
    }
  }
}
</script>

<style lang="scss" scoped>
.history-dialog {
  padding-bottom: 16px;
  &::v-deep .v-card__text {
  padding: 0 24px;
}
}
.history-table {
  border: 1px solid $color-indicator-gray;
  &::v-deep .v-data-table__wrapper {
    border-radius: 6px !important;
    height:455px;
  }
  &::v-deep tr td:nth-of-type(1) {
    min-width: 170px !important;
  }
  &::v-deep tr td:nth-of-type(3) {
    max-width: 400px !important;
  }
}
</style>
